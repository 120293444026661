module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_fd8Kraqxy4kLm4efMh3stKjLxUTMpwvewwTtI6mSKoS","apiHost":"https://posts.commcon.xyz","initOptions":{"appHost":"https://eu.posthog.com"},"head":true,"isEnabledDevMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"2024 CommCon","short_name":"Commcon","description":"A conference based in London and San Francisco, covering Open Media and Real Time Communications","start_url":"/","background_color":"#fff","icon":"src/images/assets/cc.svg","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"35e59686610d4a09afa93f80cd673491"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
